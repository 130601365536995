:root {
    box-sizing: border-box;
    font-size: calc(16px + 0.6vw);
}
@media screen and (min-width: 1200px) {
    :root{
        font-size: calc(.5em + 0.6vw);
    }
}
*, ::before, ::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
