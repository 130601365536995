.welcome-message {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    height: 100vh;
    width: 100vw;


    background-color: white;
    z-index: 1000;
    -webkit-box-shadow: 0px 2px 7px 3px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 2px 7px 3px rgba(0, 0, 0, 0.18);
    padding: 1rem;
    padding-top: 2.4rem;
    text-align: center;
}

.welcome-message h1 {
    text-decoration: underline;
    text-decoration-color: hsl(49, 100%, 50%);
    font-size: 1.5rem;
    text-align: center;
}

.welcome-message ul {
    text-align: left;
}

.welcome-message ul li {
    margin-bottom: 20px;
    font-weight: 200;
}
.welcome-message pre{
    font-size: .8rem;
    border: solid 1px #ddd;
    padding: .2rem;
}

.welcome-message button {
    font-size: 1.2rem;
    padding: .7rem;
    text-transform: uppercase;

}

.blur-bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
}
@media screen and (min-width: 992px) {
    .welcome-message {
        border-radius: 8px;

        max-width: 500px;
        max-height: 500px;
    }
}