
.tags-header > div {
    height: calc(50px + 1em);
    padding-top: 1em;
    position: fixed;
    right: 0;
    padding-right: 1rem;
    display: flex;
    left: 0;
    bottom: 20px;
    flex-direction: row;
    z-index: 1;
    justify-content: space-evenly;
    overflow-x: auto;
    padding-inline-start: 100px;
}

.button-filter {
    border-radius: 5px;
    background: #fff;
    border: solid 1px #ccc;
    padding: .5em;
    font-size: 1rem;
    cursor: pointer;
    margin-inline-start: 4px;
    opacity: .5;
    transition: opacity .3s ease-out, font-size .3s ease-in;
}

.button-filter:hover {
    background: hsl(49, 100%, 90%);
    opacity: .9;

}

.button-filter--selected {
    background: hsl(49, 100%, 50%);
    opacity: .9;
    font-size: 1.1rem;
}

.button-filter-remove {
    background: red;
    color: #fff;
}

.nott {
    height: calc(100vh);
    overflow: auto;
    position: relative;
    /* border: solid 1px red;*/
    display: flex;
    flex-direction: column;
}

.nott--inverse {
    flex-direction: column-reverse;
}

.nott__day {
    /*border: solid 1px black;*/
    min-height: 35vh;
    padding: 0 1em;
    color: #222
}

.nott_day-dense {
    min-height: unset;
}

.nott_day-today {
    min-height: 80vh;
}

.user-details {
    position: absolute;
    z-index: 100;
    bottom: 0;
    right: 15px;
    font-size: .6em;
}

.user-details > button {
    font-size: inherit;
    background: #fff;
    margin-left: 10px;
    border: solid 1px #ccc;
}

.taganimation-enter {
    opacity: 0;
}

.taganimation-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-out;
}

.taganimation-exit {
    opacity: 1;
    background: red;
    border-radius: 5px;

}

.taganimation-exit-active {
    opacity: 0;

    transition: opacity 500ms ease-in;
}

@media screen and (min-width: 992px) {
    .tags-header > div {
        justify-content: space-around;
        padding-top: 2rem;
        right: 100px;
        left: initial;
        max-width: 30%;
        bottom: initial;
        flex-wrap: wrap;
        overflow-x: initial;
        padding-inline-start: initial;
    }

    .button-filter {
        margin-bottom: 4px;

    }

}